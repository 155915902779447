import { api } from ".";
import { IServiceProvider } from "../../domain/entities/ServiceProvider";

export async function postServiceProvider(data: IServiceProvider) {
  await api.post(`/api/v1/user-service-providers/`, data);
}

export async function patchServiceProvider(data: IServiceProvider) {
  const { id, ...body } = data;
  await api.patch(`/api/v1/user-service-providers/${id}`, body);
}

export async function getServiceProvider(
  id: string
): Promise<IServiceProvider | undefined> {
  return await api.get(`/api/v1/user-service-providers/${id}`);
}

export async function getAllServiceProvider(): Promise<
  IServiceProvider[] | undefined
> {
  return await api.get(`/api/v1/user-service-providers/`);
}

export async function deleteServiceProvider(id: string) {
  await api.delete(`/api/v1/user-service-providers/${id}`);
}
