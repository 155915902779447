import { useCallback, useEffect, useState } from "react";
import { IConfig } from "../../domain/entities/Config";
import { getConfig, postService } from "../../data/service/config";
import { IService } from "../../domain/entities/Service";
import { toast } from "react-toastify";

export function useConfig() {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<IConfig>({
    modalities: [],
    neighborhood: [],
    services: [],
    service_tags: [],
    uf: "",
    city: "",
    version: "0.0.0",
  });

  const handleGetConfig = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getConfig();
      if (response) {
        setConfig(response);
      }
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmitService = useCallback(async (data: IService) => {
    setLoading(true);
    try {
      await postService(data);
      toast.success("Serviço criado com sucesso");
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetConfig();
  }, [handleGetConfig]);

  return { ...config, loading, actions: { handleSubmitService } };
}
