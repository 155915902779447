import { Form as AntForm, Card, Flex, Input } from "antd";
import React from "react";
import { Button } from "../components/Button";
import { useAuth } from "../hooks/useAuth";
import { LogoLogin } from "../assets/icon/LogoLogin";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const Login: React.FC = () => {
  const {
    loading,
    actions: { handleSignIn },
  } = useAuth();

  return (
    <section className="w-full flex items-center justify-center">
      <Card className="shadow max-w-[450px] w-full">
        <AntForm onFinish={handleSignIn} layout="vertical">
          <Flex vertical className="items-center justify-center gap-10">
            <LogoLogin width={250} height={100} />
            <Flex vertical className="gap-1 w-full">
              <AntForm.Item label="E-mail" name={"email"} className="w-full">
                <Input placeholder="Email" required size="large" />
              </AntForm.Item>

              <AntForm.Item label="Senha" name={"password"}>
                <Input.Password
                  placeholder="Senha"
                  type="password"
                  size="large"
                  required
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </AntForm.Item>

              <AntForm.Item noStyle>
                <Button
                  htmlType="submit"
                  size="large"
                  status="info"
                  loading={loading}
                >
                  Entrar
                </Button>
              </AntForm.Item>
            </Flex>
          </Flex>
        </AntForm>
      </Card>
    </section>
  );
};
