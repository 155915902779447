import { api } from ".";
import { IItem } from "../../domain/entities/Item";

export async function postItem(data: IItem) {
  await api.post(`/api/v1/item/`, data);
}

export async function putItem(data: IItem) {
  const { id, ...body } = data;
  await api.put(`/api/v1/item/aditivate/${id}`, {
    licitation: {
      aditive_date: body.licitation?.aditive_date,
      new_validity: body.licitation?.new_validity,
      doc_aditive_url: body.licitation?.doc_aditive_url,
      reason_aditive: body.licitation?.reason_aditive,
    },
    items_services: body.items_services.map((service) => ({
      id: service.id,
      quantity: service.quantity,
    })),
  });
}

export async function getItem(id: string): Promise<IItem | undefined> {
  return await api.get(`/api/v1/item/${id}`);
}

export async function getAllItem(): Promise<IItem[] | undefined> {
  return await api.get(`/api/v1/item/`);
}

export async function deleteItem(id: string) {
  await api.delete(`/items/${id}`);
}
