import { api } from ".";

export async function uploadFile(file: File): Promise<string> {
  const formData = new FormData();
  formData.append("file", file);

  return await api.post("/api/v1/bucket/uploadfile", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
