import { api } from ".";
import { ITicket, ITicketItem } from "../../domain/entities/Ticket";

export async function getAllTickets(): Promise<ITicket[] | undefined> {
  return await api.get(`api/v1/ticket/`);
}

export async function getTicket(id: string): Promise<ITicket | undefined> {
  return await api.get(`api/v1/ticket/${id}`);
}

export async function patchStatusTicket(id: string, data: string) {
  await api.patch(`api/v1/ticket/status/${id}`, {
    status: data,
  });
}

export async function postInfoTickets(id: string, data: ITicketItem) {
  await api.post(`api/v1/ticket/item/${id}`, data);
}

export async function patchTicket(id: string, data: ITicket) {
  const { id: currentId, status, ...body } = data;
  await api.patch(`api/v1/ticket/${id}`, body);
}

export async function postTicket(data: ITicket) {
  const { status, ...body } = data;
  await api.post(`api/v1/ticket/`, body);
}

export async function patchRejectTicket(id: string, data: string) {
  await api.patch(`api/v1/ticket/status/${id}`, {
    reject_reason: data,
    status: "DENIED",
  });
}
