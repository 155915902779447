import { useCallback, useContext, useState } from "react";
import { UserContext } from "../../domain/context/user/UserContext";
import { signIn } from "../../data/service/auth";

export function useAuth() {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSignIn = useCallback(
    async (values: any) => {
      setLoading(true);
      try {
        const response = await signIn({
          email: values.email,
          password: values.password,
        });

        if (response?.id) {
          userContext.actions.signIn(response);
        }
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    [userContext.actions]
  );

  return {
    loading,
    actions: {
      handleSignIn,
    },
  };
}
