import { api } from ".";
import { IConfig } from "../../domain/entities/Config";
import { IService } from "../../domain/entities/Service";

export async function getConfig(): Promise<IConfig | undefined> {
  return await api.get("/api/v1/config/");
}

export async function postService(data: IService): Promise<void> {
  return await api.post("/api/v1/config/create/service", data);
}
