import { api } from ".";
import {
  IRequirement,
  IRequirementService,
} from "../../domain/entities/Requirement";

export async function getRequirement(
  id: string
): Promise<IRequirement | undefined> {
  return await api.get(`api/v1/requirement/${id}`);
}

export async function getAllRequirements(): Promise<
  IRequirement[] | undefined
> {
  return await api.get(`api/v1/requirement/`);
}

export async function getAllRequirementsServiceProvider(): Promise<
  IRequirementService[] | undefined
> {
  return await api.get(`api/v1/requirement/requirement-services`);
}

export async function patchStatusRequirements(id: string, data: string) {
  await api.patch(`api/v1/requirement/update-status/${id}`, { status: data });
}

export async function patchRejectRequirements(id: string, reason: string) {
  await api.patch(`api/v1/requirement/update-status/${id}`, {
    rejected_reason: reason,
    status: "REJECTED",
  });
}

export async function postRequirement(data: IRequirement) {
  await api.post(`api/v1/requirement/`, data);
}

export async function patchRequirement(data: IRequirement) {
  const { id, ...body } = data;

  await api.patch(`api/v1/requirement/update-info/${id}`, body);
}

export async function postNewRequirementService(
  requirementId: string,
  data: { quantity: number; service_id: string }[]
) {
  await api.post(`api/v1/requirement/add-service/${requirementId}`, data);
}

export async function postConcludeRequirementService(
  serviceId: string,
  data: { quantity_consumed: number; invoice_url: string }
) {
  await api.post(
    `api/v1/requirement/requirement-services/conclude/${serviceId}`,
    data
  );
}

export async function getConcludeRequirement(id: string) {
  await api.get(`api/v1/requirement/conclude/${id}`);
}
