import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Grid,
  Layout,
  Menu,
  MenuProps,
  Typography,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../../domain/context/user/UserContext";
import { ROUTES } from "../Router";

import { RiServiceLine } from "react-icons/ri";
import { GiSettingsKnobs } from "react-icons/gi";
import { AiOutlineDashboard } from "react-icons/ai";
import { LiaUserShieldSolid } from "react-icons/lia";
import { FaHandHoldingHeart, FaBoxOpen } from "react-icons/fa";
import { GrDocumentTest } from "react-icons/gr";
import { TbDogBowl, TbReportMedical } from "react-icons/tb";
import { ImHammer2 } from "react-icons/im";
import {
  MdMail,
  MdOutlineDesignServices,
  MdOutlineInventory,
  MdPets,
} from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { FaShieldDog } from "react-icons/fa6";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Breadcrumb } from "./Breadcrumb";
import { UserOutlined } from "@ant-design/icons";
import { Logo } from "../assets/icon/Logo";
import { LogoIcon } from "../assets/icon/LogoIcon";
import { useConfig } from "../hooks/useConfig";
import { ItemType } from "antd/es/menu/interface";

const { useBreakpoint } = Grid;

const { Text } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  hasPermission: boolean = false,
  disabled: boolean = false,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    style: { display: hasPermission ? "block" : "none" },
    key,
    icon,
    children,
    label: children ? (
      label
    ) : (
      <Link to={{ pathname: key.toString() }}>{label}</Link>
    ),
    type,
    disabled,
  } as MenuItem;
}

export const PrivateRoute: React.FC = () => {
  const userContext = useContext(UserContext);

  const config = useConfig();

  const [collapsed, setCollapsed] = React.useState(false);
  const handleToggleMenu = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const navigate = useNavigate();

  const screens = useBreakpoint();

  const checkPermission = useCallback(
    (scope: string) => userContext.user?.scopes?.includes(scope) ?? false,
    [userContext.user?.scopes]
  );

  const menuItems: MenuProps["items"] = useMemo(
    () => [
      getItem(
        "Painel de Controle",
        ROUTES.DASHBOARD.ROOT,
        <AiOutlineDashboard size={18} />,
        checkPermission("dashboard:read")
      ),

      getItem(
        "Prestador de Serviços",
        ROUTES.DASHBOARD.SERVICE_PROVIDER.ROOT,
        <RiServiceLine size={18} />,
        checkPermission("service_provider:read")
      ),
      getItem(
        "Itens",
        "Itens",
        <MdOutlineInventory size={18} />,
        checkPermission("item:read"),
        false,
        [
          getItem(
            "Licitações",
            ROUTES.DASHBOARD.ITEMS.ROOT,
            <ImHammer2 size={18} />,
            checkPermission("item:read")
          ),
          getItem(
            "Estoque",
            ROUTES.DASHBOARD.ITEMS.STOCK,
            <FaBoxOpen size={18} />,
            checkPermission("item:stock:read")
          ),
        ]
      ),
      getItem(
        "Denúncias",
        ROUTES.DASHBOARD.DENOUNCEMENT.ROOT,
        <IoWarningOutline size={18} />,
        checkPermission("denouncement:read")
      ),
      getItem(
        "Chamados",
        ROUTES.DASHBOARD.TICKET.ROOT,
        <TbReportMedical size={18} />,
        checkPermission("ticket:read")
      ),
      getItem(
        "Protetores",
        "1",
        <LiaUserShieldSolid size={18} />,
        checkPermission("protector:read"),
        false,
        [
          getItem(
            "Solicitações",
            ROUTES.DASHBOARD.PROTECTOR.ROOT,
            <MdMail size={18} />,
            checkPermission("protector:interview:read:all")
          ),
          getItem(
            "Protetores Ativos",
            ROUTES.DASHBOARD.PROTECTOR.ACTIVE.ROOT,
            <FaShieldDog size={18} />,
            checkPermission("protector:read")
          ),
        ]
      ),
      getItem(
        "Campanhas",
        ROUTES.DASHBOARD.CAMPAIGN.ROOT,
        <FaHandHoldingHeart size={18} />,
        checkPermission("campaign:read"),
        false
      ),
      getItem(
        "Requerimentos",
        ROUTES.DASHBOARD.REQUIREMENT.ROOT,
        <GrDocumentTest size={18} />,
        true
      ),
      getItem(
        "Animais",
        ROUTES.DASHBOARD.ANIMALS.ROOT,
        <MdPets size={18} />,
        checkPermission("pet:read:all"),
        false
      ),
      getItem(
        "Programas",
        ROUTES.DASHBOARD.PROGRAM.ROOT,
        <TbDogBowl size={18} />,
        checkPermission("dashboard:read"),
        true,
        [
          getItem(
            "Banco de Ração",
            ROUTES.DASHBOARD.PROGRAM.FOOD_BANK,
            <ImHammer2 size={18} />,
            true
          ),
        ]
      ),

      getItem(
        "Configurações",
        "Configurações",
        <GiSettingsKnobs size={18} />,
        checkPermission("config:create:service"),
        false,
        [
          getItem(
            "Serviços",
            ROUTES.DASHBOARD.SERVICE.ROOT,
            <MdOutlineDesignServices size={18} />,
            checkPermission("config:create:service")
          ),
        ]
      ),
    ],
    [checkPermission]
  );
  const avatarMenuItems = useMemo<ItemType[]>(
    () => [
      {
        key: "logout",
        label: "Sair",
        onClick: () => userContext.actions.signOut(),
      },
      {
        key: "privacyPolicy",
        label: (
          <a
            href="https://www.propata.com.br/_files/ugd/26d3bd_320990617b9d48c1bc13eea61364799f.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
          </a>
        ),
      },
      {
        key: "version",
        label: <label className="text-white-400">v{config.version}</label>,
      },
    ],
    [userContext.actions, config.version]
  );

  useEffect(() => {
    setCollapsed(!screens.md);
  }, [screens]);

  if (!userContext.user) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <Flex className="w-full">
      <Layout>
        <Sider
          theme="dark"
          collapsible
          collapsed={collapsed}
          trigger={null}
          className="!bg-purple-100"
        >
          <Flex
            rootClassName="px-5 h-16"
            justify={collapsed ? "center" : "left"}
            align="center"
          >
            <h1 className="text-3xl m-0 font-bold text-white hover:cursor-pointer">
              <Link to={ROUTES.DASHBOARD.ROOT}>
                {collapsed ? (
                  <LogoIcon width={40} height={40} />
                ) : (
                  <Logo width={140} height={140} />
                )}
              </Link>
            </h1>
          </Flex>
          <Menu
            theme="dark"
            onClick={onClick}
            mode="inline"
            items={menuItems}
            data-testid="aside-menu"
            className="!bg-purple-100"
          />
        </Sider>
        <Layout>
          <Header className="!bg-purple-200 !pl-1 !pr-4 flex items-center">
            <Button
              type="text"
              icon={collapsed ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
              onClick={handleToggleMenu}
              className="flex items-center justify-center !w-10 !h-10 !text-lg text-brown "
            />
            <div className="flex-1 " />
            <Flex gap={"middle"} align="center">
              <Text className="text-white">
                Olá,{" "}
                <Text className="font-bold text-white">
                  {userContext.user?.full_name}
                </Text>
              </Text>
              <Dropdown
                menu={{ items: avatarMenuItems }}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Avatar shape="square" icon={<UserOutlined />} />
              </Dropdown>
            </Flex>
          </Header>
          <Content className="p-4 overflow-auto">
            <Flex
              vertical
              gap={16}
              align="flex-start"
              className="!h-full max-w-[1440px] mx-auto"
            >
              <Breadcrumb />
              <Outlet />
            </Flex>
          </Content>
        </Layout>
      </Layout>
    </Flex>
  );
};
