import { useCallback, useState } from "react";
import { IDashboard } from "../../domain/entities/Dashboard";
import { getDashboard } from "../../data/service/dashboard";

export function useDashboard() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<IDashboard>();

  const handleGetDashboard = useCallback(
    async (neighborhood?: string, months?: number) => {
      setLoading(true);
      try {
        const response = await getDashboard(neighborhood, months);
        setDashboard(response);
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loading, dashboard, actions: { handleGetDashboard } };
}
