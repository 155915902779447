import { useCallback, useState } from "react";
import { IItem, ItemType } from "../../domain/entities/Item";
import {
  deleteItem,
  getAllItem,
  getItem,
  postItem,
  putItem,
} from "../../data/service/item";
import dayjs from "dayjs";
import { uploadFile } from "../../data/service/bucket";

export function useItem() {
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<IItem>();
  const [itemList, setItemList] = useState<IItem[]>();

  const handleSubmitItem = useCallback(async (values: any) => {
    try {
      let body: any = { licitation: {} };

      setLoading(true);

      Object.entries(values).forEach(([key, value]) => {
        if (key.includes("licitation.")) {
          body.licitation[key.replace("licitation.", "")] = value;
          return;
        }

        body[key] = value;
      });

      if (body.type === ItemType.LICITATION) {
        body.licitation.start_validity = dayjs(
          body.licitation.start_validity
        ).format("YYYY-MM-DD");
      } else {
        const { licitation, ...rest } = body;
        body = rest;
      }

      if (values.id) {
        if (body.licitation.new_validity) {
          body.licitation.new_validity = dayjs(
            body.licitation.new_validity
          ).format("YYYY-MM-DD");
        }

        if (body.licitation.aditive_date) {
          body.licitation.aditive_date = dayjs(
            body.licitation.aditive_date
          ).format("YYYY-MM-DD");
        }

        body.licitation.doc_aditive_url = await uploadFile(
          body.licitation.doc_aditive_url.file
        );

        await putItem(body);
      } else {
        body.doc_url = await uploadFile(body.doc_url.file);

        await postItem(body);
      }
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetItem = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const selectedItem = await getItem(id);
      setItem(selectedItem);
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetAllItem = useCallback(async () => {
    setLoading(true);
    try {
      const allItems = await getAllItem();
      setItemList(allItems);
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteItem = useCallback(async (id: string) => {
    try {
      setLoading(true);
      await deleteItem(id);
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    item,
    itemList,
    actions: {
      handleSubmitItem,
      handleGetItem,
      handleGetAllItem,
      handleDeleteItem,
    },
  };
}
