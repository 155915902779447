import { Col, Flex, Modal, Row, Form as AntForm, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { Button } from "../../../components/Button";
import { useProtector } from "../../../hooks/useProtector";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { toast } from "react-toastify";
import { TutorCard } from "../../../components/Cards/TutorCard";

export const ProtectorInfo: React.FC = () => {
  const userContext = useContext(UserContext);
  const [form] = AntForm.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    protector,
    actions: {
      handleGetProtectors,
      handlePatchRejectReason,
      handlePatchAcceptReason,
      handleGetAllProtectors,
    },
  } = useProtector();

  const approveProtector = () => {
    handlePatchAcceptReason(id as string);
    navigate(`${ROUTES.DASHBOARD.PROTECTOR.ROOT}`);
    handleGetAllProtectors();
  };

  const openModal = (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (values: any) => {
    const { reject_reason } = values;
    try {
      if (protector?.status === "DENIED") {
        toast.error("O Protetor já foi Rejeitado.");
        form.resetFields();
      } else {
        await handlePatchRejectReason(selectedId, reject_reason);
        closeModal();
        navigate(`${ROUTES.DASHBOARD.PROTECTOR.ROOT}`);
        await handleGetAllProtectors();
      }
    } catch (error: any) {
      new Error(error);
    }
  };

  useEffect(() => {
    handleGetProtectors(id as string);
  }, [id, handleGetProtectors]);

  return (
    <>
      <Flex vertical gap="small" align="flex-end" className="w-full">
        <Row className="w-full">
          <Col span={24}>
            {protector?.tutor && <TutorCard {...protector?.tutor} />}
          </Col>
        </Row>
        <Row className="gap-3 ml-auto">
          {userContext.user?.scopes?.includes(
            "protector:interview:change_status"
          ) && (
            <Button
              data-testid="approve-protector"
              type="default"
              status="success"
              onClick={approveProtector}
            >
              Aprovar
            </Button>
          )}
          {userContext.user?.scopes?.includes(
            "protector:interview:change_status"
          ) && (
            <Button
              data-testid="reject-protector"
              type="default"
              status="danger"
              onClick={() => openModal(id as string)}
            >
              Rejeitar
            </Button>
          )}
          <Button type="default" status="info" onClick={() => navigate(-1)}>
            Voltar
          </Button>
        </Row>
      </Flex>
      <Modal
        title="Rejeição de solicitação"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            onClick={form.submit}
            data-testid="send-button-modal"
            loading={false}
          >
            Enviar
          </Button>,
        ]}
      >
        <AntForm layout="vertical" onFinish={handleSubmit} form={form}>
          <AntForm.Item
            name="reject_reason"
            label="Justificativa"
            rules={[
              {
                required: true,
                message: "Por favor, adicione uma justificativa.",
              },
            ]}
          >
            <Input.TextArea
              className="h-32 resize-none"
              placeholder="Adicione um motivo para a rejeição"
            />
          </AntForm.Item>
        </AntForm>
      </Modal>
    </>
  );
};
