import { toast } from "react-toastify";
import { api } from ".";
import {
  IDenouncement,
  IDenouncementItems,
} from "../../domain/entities/Denouncement";

export async function getDenouncement(
  id: string
): Promise<IDenouncement | undefined> {
  return await api.get(`api/v1/denouncement/${id}`);
}

export async function getAllDenouncements(): Promise<
  IDenouncement[] | undefined
> {
  return await api.get(`api/v1/denouncement/`);
}
export async function postInfoDenouncements(
  id: string,
  data: IDenouncementItems
) {
  await api.post(`api/v1/denouncement/item/${id}`, data);
  toast.success("Informações Inseridas com sucesso");
}
