import React, { useCallback, useEffect, useState } from "react";

import {
  Form as AntForm,
  Input,
  Row,
  Col,
  Flex,
  Card,
  Select,
  notification,
  Checkbox,
} from "antd";
import { Button } from "../../../components/Button";
import { useServiceProvider } from "../../../hooks/useServiceProvider";
import { UF } from "../../../utils/uf";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import axios from "axios";

import MaskedInput from "antd-mask-input";

export const Form: React.FC = () => {
  const navigate = useNavigate();

  const params = useParams();

  const [form] = AntForm.useForm();

  const [isEditing, setIsEditing] = useState(false);

  const {
    serviceProvider,
    actions: { handleSubmitServiceProvider, handleGetServiceProvider },
  } = useServiceProvider();

  useEffect(() => {
    if (params.id) {
      handleGetServiceProvider(params.id);
      setIsEditing(true);
    }
  }, [params.id, handleGetServiceProvider]);

  useEffect(() => {
    if (serviceProvider) {
      form.setFieldsValue(serviceProvider);
    }
  }, [serviceProvider, form]);

  const handleSubmit = useCallback(
    async (values: any) => {
      await handleSubmitServiceProvider(values);

      navigate(ROUTES.DASHBOARD.SERVICE_PROVIDER.ROOT);
    },
    [handleSubmitServiceProvider, navigate]
  );

  const handleCepBlur = async () => {
    const cep = form.getFieldValue("user.cep");
    if (cep && cep.replace("-", "").length === 8) {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        const { logradouro, localidade, uf, bairro } = response.data;
        form.setFieldsValue({
          "user.address": logradouro,
          "user.city": localidade,
          "user.uf": uf,
          "user.neighborhood": bairro,
        });
      } catch (erro: any) {
        if (erro.response && erro.response.status === 429) {
          notification.error({
            message: "Erro ao buscar CEP",
            description:
              "O serviço de busca de CEP atingiu o limite de requisições. Por favor, tente novamente mais tarde.",
          });
        } else {
          notification.error({
            message: "Erro ao buscar CEP",
            description:
              "Não foi possível buscar o endereço. Verifique o CEP e tente novamente.",
          });
        }
      }
    }
  };

  const validateCNPJ = (_: unknown, value: string) => {
    const cnpj = value.replace(/[^\d]/g, "");
    if (cnpj.length === 14) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Por favor, insira um CNPJ completo."));
  };

  return (
    <AntForm
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      className="w-full"
    >
      <AntForm.Item name={"id"} noStyle hidden>
        <Input />
      </AntForm.Item>
      <Flex vertical gap="middle">
        <Card>
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <AntForm.Item
                name={["user", "full_name"]}
                label="Nome Fantasia"
                rules={[
                  { required: true, message: "Nome Fantasia Obrigatório" },
                ]}
              >
                <Input placeholder="Nome Fantasia" />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <AntForm.Item
                name={["user", "id_number"]}
                label="CNPJ"
                initialValue={form.getFieldValue("user.id_number")}
                rules={[
                  { required: true, message: "CNPJ é obrigatório" },
                  { validator: validateCNPJ },
                ]}
              >
                <MaskedInput disabled={isEditing} mask={"00.000.000/0000-00"} />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <AntForm.Item
                name={["user", "phone"]}
                label="Telefone"
                rules={[{ required: true, message: "Telefone é obrigatório" }]}
              >
                <MaskedInput mask={"(00) [0]0000-0000"} />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <AntForm.Item
                name={["user", "email"]}
                label="Email"
                rules={[{ required: true, message: "Email é obrigatório" }]}
              >
                <Input placeholder="Email" disabled={isEditing} />
              </AntForm.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={8}>
              <AntForm.Item name={["user", "cep"]} label="CEP" required>
                <MaskedInput mask={"00000-000"} onBlur={handleCepBlur} />
              </AntForm.Item>
            </Col>
            <Col span={24}>
              <Row gutter={{ xs: 8, md: 16 }}>
                <Col xs={24} sm={24} md={10}>
                  <AntForm.Item
                    name={["user", "address"]}
                    label="Endereço"
                    required
                  >
                    <Input placeholder="Endereço" required />
                  </AntForm.Item>
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <AntForm.Item name={["user", "city"]} label="Cidade" required>
                    <Input placeholder="Cidade" required />
                  </AntForm.Item>
                </Col>
                <Col xs={24} sm={24} md={4}>
                  <AntForm.Item name={["user", "uf"]} label="Estado" required>
                    <Select
                      showSearch
                      placeholder="Selecione"
                      data-testid="uf-select"
                      options={[
                        ...UF.map((uf) => ({
                          label: uf,
                          value: uf,
                        })),
                      ]}
                    />
                  </AntForm.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <AntForm.Item
                    name={["user", "neighborhood"]}
                    label="Bairro"
                    required
                  >
                    <Input placeholder="Bairro" required />
                  </AntForm.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <Card>
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <AntForm.Item
                name={"legal_representative"}
                label="Representante Legal"
                required
              >
                <Input placeholder="Representante Legal" required />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <AntForm.Item
                name={"technical_manager"}
                label="Responsável Técnico"
                required
              >
                <Input placeholder="Responsável Técnico" required />
              </AntForm.Item>
            </Col>
            <Col span={24}>
              <AntForm.Item name={"information"} label="Informação" required>
                <Input.TextArea
                  style={{ height: "130px", resize: "none" }}
                  placeholder="Informação"
                />
              </AntForm.Item>
            </Col>
            <Col span={12}>
              <Row align="top">
                <Col flex="none">

                  <Checkbox
                    style={{
                      marginRight: "8px",
                    }}
                  />
                </Col>
                <Col flex="auto" span={12}>
                  <span>
                    Os seus dados pessoais serão coletados com o seu consentimento e com
                    a finalidade de atender as suas solicitações. Ao preencher esse
                    formulário você nos dá a sua permissão para a coleta e o tratamento
                    de seus dados pessoais. Antes de fornecer seus dados, leia
                    atentamente a nossa{" "}
                    <a
                      href="https://www.propata.com.br/_files/ugd/26d3bd_320990617b9d48c1bc13eea61364799f.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de Privacidade
                    </a>
                    .
                  </span>
                </Col>
              </Row>
            </Col>

          </Row>
        </Card>
        <Flex justify="flex-end" align="center" gap={20}>
          <AntForm.Item noStyle>
            <Button status="danger" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
          </AntForm.Item>
          <AntForm.Item noStyle>
            <Button htmlType="submit" status="success">
              Enviar
            </Button>
          </AntForm.Item>
        </Flex>
      </Flex>
    </AntForm>
  );
};
