import { api } from ".";
import { IPet, IPetFilters } from "../../domain/entities/Pet";

const buildQueryParams = (filters: IPetFilters): IPetFilters => {
  const filteredEntries = Object.entries(filters).filter(
    ([, value]) => value !== ""
  );
  return Object.fromEntries(filteredEntries) as IPetFilters;
};

export async function getAllPets(
  filters: IPetFilters
): Promise<IPet[] | undefined> {
  const params = buildQueryParams(filters);
  return await api.get(`api/v1/pet/`, {
    params,
  });
}

export async function patchPetMicrochip(id: string, data: string) {
  await api.patch(`api/v1/pet/${id}`, {
    microchip_number: data,
  });
}

export async function getAllPetsByIdNumber(
  id: string
): Promise<IPet[] | undefined> {
  return await api.get(`/api/v1/pet/tutor-id-number/${id}`);
}
