import {
  Input,
  Form as AntForm,
  Flex,
  Card,
  Row,
  Col,
  Typography,
  Select,
  DatePicker,
  Upload,
  Image,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useConfig } from "../../../hooks/useConfig";
import { Button } from "../../../components/Button";
import { useItem } from "../../../hooks/useItem";
import { FaTrash } from "react-icons/fa";
import { useCampaign } from "../../../hooks/useCampaign";
import dayjs from "dayjs";
import { UploadFile } from "antd/lib";
import { FileType, getBase64 } from "../../../utils/image";
import isBetween from "dayjs/plugin/isBetween";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ROUTES } from "../../../Router";

dayjs.extend(isBetween);

const { Text } = Typography;

export const Form: React.FC = () => {
  const [form] = AntForm.useForm();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [currentItem, setCurrentItem] = useState<string>();

  const { neighborhood } = useConfig();

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const {
    itemList,
    actions: { handleGetAllItem },
  } = useItem();

  const {
    loading,
    campaign,
    actions: { handleGetCampaign, handleCreateCampaign },
  } = useCampaign();

  const handleItemChange = useCallback(
    (value: string) => {
      form.setFieldsValue({ order_id: value });
      setCurrentItem(value);
    },
    [form]
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  useEffect(() => {
    const item_id = searchParams.get("order_id");

    if (item_id) {
      handleItemChange(item_id);
    }
  }, [searchParams, handleItemChange]);

  useEffect(() => {
    if (id) {
      handleGetCampaign(id);
    }

    handleGetAllItem();
  }, [id, handleGetCampaign, handleGetAllItem]);

  const handleSubmitCampaign = useCallback(
    async (data: any) => {
      await handleCreateCampaign(data);

      navigate(ROUTES.DASHBOARD.CAMPAIGN.ROOT);
    },
    [handleCreateCampaign, navigate]
  );

  useEffect(() => {
    if (campaign) {
      setIsEditing(true);

      const locations = campaign.campaign_locations.map((location) => ({
        ...location,
        date: dayjs(location.date),
      }));

      form.setFieldsValue({
        ...campaign,
        campaign_date: [
          dayjs(campaign.campaign_start_date),
          dayjs(campaign.campaign_end_date),
        ],
        subscription_date: [
          dayjs(campaign.subscription_start_date),
          dayjs(campaign.subscription_end_date),
        ],
        campaign_locations: locations,
      });
    }
  }, [id, form, campaign]);

  return (
    <Spin tip="Carregando..." spinning={loading} wrapperClassName="w-full">
      <AntForm
        onFinish={handleSubmitCampaign}
        layout="vertical"
        form={form}
        className="w-full"
      >
        <AntForm.Item name={"id"} noStyle hidden>
          <Input />
        </AntForm.Item>
        <Flex vertical gap="middle">
          <Card
            title={
              <div className="flex items-center justify-between">
                <Flex>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {isEditing ? (
                        <Text className="text-xl font-bold mr-2">
                          Campanha:{" "}
                          <Text className="text-base font-medium">
                            {campaign?.name}
                          </Text>
                        </Text>
                      ) : (
                        <Text className="text-xl font-bold mr-2">
                          Nova Campanha
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Flex>
              </div>
            }
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} md={24} xl={12}>
                    <AntForm.Item name="name" label="Nome da Campanha" required>
                      <Input
                        disabled={isEditing}
                        placeholder="Digite o nome"
                        required
                      />
                    </AntForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={12}>
                    <AntForm.Item
                      name="notice_url"
                      label="Edital"
                      rules={[
                        { required: true, message: "Edital é obrigatório" },
                      ]}
                    >
                      {isEditing ? (
                        <Link to={form.getFieldValue("notice_url")}>
                          <Button status="info">Visualizar Edital</Button>
                        </Link>
                      ) : (
                        <Upload
                          maxCount={1}
                          multiple={false}
                          beforeUpload={() => false}
                        >
                          <Button status="info" icon={<UploadOutlined />}>
                            Selecionar Edital
                          </Button>
                        </Upload>
                      )}
                    </AntForm.Item>
                  </Col>
                </Row>

                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <AntForm.Item
                      name="subscription_date"
                      label="Data das Inscrições"
                      rules={[
                        { required: true, message: "Data é obrigatória" },
                        {
                          validator: (_, value) => {
                            if (value && dayjs(value[0]).isBefore(dayjs(), "day")) {
                              return Promise.reject(
                                "Data inicial das inscrições deve ser maior que a data atual"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        disabled={isEditing}
                        format="DD/MM/YYYY"
                        className="w-full"
                        data-testid="datepicker-subscription_date"
                        placeholder={["Início", "Fim"]}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <AntForm.Item
                      name="campaign_date"
                      label="Data da Campanha"
                      rules={[
                        { required: true, message: "Data é obrigatória" },
                        {
                          validator(_, value) {
                            const subscriptionDate = form.getFieldValue("subscription_date");

                            if (!subscriptionDate || subscriptionDate.length < 2) {
                              return Promise.resolve();
                            }

                            if (
                              value &&
                              value[0] &&
                              dayjs(value[0]).isBefore(dayjs(subscriptionDate[1]).add(2, "day"))
                            ) {
                              return Promise.reject(
                                "Data inicial da campanha deve ser maior que a data final das inscrições"
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        disabled={isEditing}
                        format="DD/MM/YYYY"
                        className="w-full"
                        data-testid="datepicker-campaign_date"
                        placeholder={["Início", "Fim"]}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>

                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} lg={12}>
                    <AntForm.Item label="Licitação" name="item_service_id">
                      <Select
                        placeholder="Selecione uma Licitação"
                        data-testid="item-select"
                        disabled={isEditing}
                        value={currentItem}
                        onChange={(value) => handleItemChange(value)}
                        options={[
                          ...(itemList
                            ? itemList.map((item) => ({
                              label: (
                                <span>
                                  {item.type === "LICITATION"
                                    ? "Licitação"
                                    : "Compra Direta"}
                                  : {item.order_id}
                                </span>
                              ),
                              title: "manager",
                              options: item.items_services.map((service) => ({
                                label: service.service.service_name,
                                value: service.id,
                              })),
                            }))
                            : []),
                        ]}
                      />
                    </AntForm.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={12}>
                    <AntForm.Item
                      label="Quantidade que será gasta"
                      name="amount"
                      required
                    >
                      <Input
                        disabled={isEditing}
                        placeholder="Quantidade"
                        required
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={8}>
                <AntForm.Item
                  name="image_url"
                  label="Banner"
                  rules={[{ required: true, message: "Banner é obrigatório" }]}
                >
                  {!isEditing ? (
                    <>
                      <Upload
                        maxCount={1}
                        multiple={false}
                        beforeUpload={() => false}
                        listType="picture-card"
                        accept="image/png, image/jpeg, image/jpg"
                        onPreview={handlePreview}
                        onChange={(info) =>
                          form.setFieldValue("image_url", info)
                        }
                      >
                        <button
                          className="border-0 bg-transparent w-full h-full"
                          type="button"
                        >
                          <PlusOutlined />
                          <div>Upload</div>
                        </button>
                      </Upload>

                      {previewImage && (
                        <Image
                          wrapperClassName="hidden"
                          className="w-full h-full"
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                    </>
                  ) : (
                    <Image
                      className="object-cover"
                      width={100}
                      height={100}
                      src={form.getFieldValue("image_url")}
                    />
                  )}
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <AntForm.List
                  name="campaign_locations"
                  initialValue={form.getFieldValue("campaign") ?? [{}]}
                >
                  {(fields, { add, remove }) => (
                    <Flex vertical gap="middle" align="center">
                      {fields.map((field, index) => {
                        return (
                          <Card key={field.key} className="w-full">
                            <Row gutter={{ xs: 8, md: 16 }}>
                              <Col xs={24} sm={24} md={12} lg={6}>
                                <AntForm.Item
                                  name={[field.name, "neighborhood"]}
                                  label="Bairro"
                                  rules={[
                                    {
                                      required: !isEditing,
                                      message: "O Bairro é obrigatório",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    disabled={isEditing}
                                    placeholder="Selecione"
                                    data-testid="select-neighborhood"
                                    className="w-60"
                                    options={[
                                      ...neighborhood.map((item) => {
                                        return {
                                          value: item,
                                          label: item,
                                        };
                                      }),
                                    ]}
                                  />
                                </AntForm.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={6}>
                                <AntForm.Item
                                  name={[field.name, "date"]}
                                  label="Data"
                                  rules={[
                                    {
                                      required: true,
                                      message: "A Data é obrigatória",
                                    },
                                    {
                                      validator: (_, value) => {
                                        const campaignDate = form.getFieldValue("campaign_date");

                                        if (!value || !campaignDate || campaignDate.length < 2) {
                                          return Promise.resolve();
                                        }

                                        const [startDate, endDate] = campaignDate.map((date: number) =>
                                          dayjs(date)
                                        );

                                        if (
                                          !dayjs(value).isSame(startDate, "day") &&
                                          !dayjs(value).isSame(endDate, "day") &&
                                          !dayjs(value).isBetween(startDate, endDate, "day", "[]")
                                        ) {
                                          return Promise.reject(
                                            "A Data do bairro deve estar entre a data da campanha"
                                          );
                                        }

                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isEditing}
                                    format="DD/MM/YYYY"
                                    className="w-full"
                                  />
                                </AntForm.Item>
                              </Col>
                            </Row>

                            {!isEditing && fields.length > 1 && (
                              <Button
                                danger
                                data-testid="remove-campaign"
                                className="absolute top-2 right-2"
                                onClick={() => remove(index)}
                              >
                                <FaTrash />
                              </Button>
                            )}
                          </Card>
                        );
                      })}

                      {!isEditing && (
                        <Button
                          type="dashed"
                          data-testid="add-campaign"
                          onClick={() => add()}
                          className="!w-10 !h-10 rounded-full text-xl flex items-center justify-center"
                        >
                          +
                        </Button>
                      )}
                    </Flex>
                  )}
                </AntForm.List>
              </Col>
            </Row>
          </Card>

          <Flex justify="flex-end" align="center" gap={20}>
            <AntForm.Item noStyle>
              <Button status="danger" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </AntForm.Item>
            {!isEditing && (
              <AntForm.Item noStyle>
                <Button
                  htmlType="submit"
                  status="success"
                  data-testid="submit-form-campaign"
                >
                  Enviar
                </Button>
              </AntForm.Item>
            )}
          </Flex>
        </Flex>
      </AntForm>
    </Spin>
  );
};
