import { api } from ".";
import {
  IProtector,
  IProtectorInterview,
} from "../../domain/entities/Protector";

export async function getAllProtectors(): Promise<
  IProtectorInterview[] | undefined
> {
  return await api.get(`api/v1/protector-interview/`);
}

export async function getProtector(
  id: string
): Promise<IProtectorInterview | undefined> {
  return await api.get(`api/v1/protector-interview/${id}`);
}

export async function getAllActiveProtectors(): Promise<
  IProtector[] | undefined
> {
  return await api.get(`api/v1/user-protector/`);
}

export async function getActiveProtector(
  id: string
): Promise<IProtector | undefined> {
  return await api.get(`api/v1/user-protector/${id}`);
}

export async function patchRejectProtector(id: string, data: string) {
  await api.patch(`api/v1/protector-interview/status/${id}`, {
    reject_reason: data,
    status: "DENIED",
  });
}

export async function patchAcceptProtector(id: string) {
  await api.patch(`api/v1/protector-interview/status/${id}`, {
    status: "APPROVED",
  });
}

export async function deleteProtector(id: string) {
  await api.delete(`api/v1/user-protector/delete/${id}`);
}
