import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCampaign } from "../../hooks/useCampaign";
import {
  Card,
  Flex,
  Modal,
  Space,
  Table,
  TimePicker,
  Tooltip,
  Typography,
  Form as AntForm,
  Select,
  Col,
  Row,
  Spin,
  Empty,
  Input,
} from "antd";
import { TableProps } from "antd/lib";
import {
  CampaignPetStatus,
  ICampaignLocations,
  ICampaignPet,
} from "../../../domain/entities/Campaign";
import { PetCard } from "../Cards/PetCard";
import { IPet } from "../../../domain/entities/Pet";
import { TutorCard } from "../Cards/TutorCard";
import { FaEye } from "react-icons/fa";
import { UserContext } from "../../../domain/context/user/UserContext";
import { FaRegCircleCheck } from "react-icons/fa6";
import dayjs from "dayjs";
import { Button } from "../Button";
import { MaskedInput } from "antd-mask-input";
import { usePet } from "../../hooks/usePet";
import { MdCancel } from "react-icons/md";
import { ROUTES } from "../../Router";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

type PetsProps = {
  campaignLocation: ICampaignLocations;
  listType: string;
  filter: any;
};

export const PetsList: React.FC<PetsProps> = ({
  campaignLocation,
  listType,
  filter,
}) => {
  const [selectedPet, setSelectedPet] = useState<IPet>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenReason, setIsModalOpenReason] = useState(false);
  const [searchPetList, setSearchPetList] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [form] = AntForm.useForm();
  const [rejectForm] = AntForm.useForm();

  const [cpf, setCpf] = useState("");

  const userContext = useContext(UserContext);

  const location = useLocation();

  const {
    loading,
    campaignPetList,
    actions: {
      handleGetAllCampaignPetsByLocation,
      handleSendToQueue,
      handleChangeAttendenceTime,
      handlePostPetCampaign,
      handleConfirmAttendence,
      handleDenyAttendence,
    },
  } = useCampaign();

  const {
    petList,
    actions: { handleGetAllPetsByIdNumber },
  } = usePet();

  const handleGetPets = useCallback(async () => {
    await handleGetAllCampaignPetsByLocation(
      campaignLocation.id,
      listType,
      filter
    );
  }, [campaignLocation, listType, filter, handleGetAllCampaignPetsByLocation]);

  const handleSearch = useCallback(async () => {
    await handleGetAllPetsByIdNumber(cpf);
    setSearchPetList(true);
  }, [handleGetAllPetsByIdNumber, cpf]);

  useEffect(() => {
    handleGetPets();
  }, [handleGetAllCampaignPetsByLocation, handleGetPets]);

  const sendToQueue = useCallback(
    async (petId: string) => {
      await handleSendToQueue(petId);
      await handleGetPets();
    },
    [handleGetPets, handleSendToQueue]
  );

  const changeAttendenceTime = useCallback(
    async (petId: string, attendence_time: string) => {
      await handleChangeAttendenceTime(petId, attendence_time);
      await handleGetPets();
    },
    [handleGetPets, handleChangeAttendenceTime]
  );

  const openModal = useCallback((id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  }, []);

  const openModalRejectReason = useCallback((id: string) => {
    setSelectedId(id);
    setIsModalOpenReason(true);
  }, []);

  const closeModalRejectReason = useCallback(() => {
    setIsModalOpenReason(false);
    form.resetFields();
  }, [form]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    form.resetFields();
    setCpf("");
    setSearchPetList(false);
  }, [form]);

  const handleSubmit = useCallback(
    async (values: any) => {
      await handlePostPetCampaign(
        selectedId,
        dayjs(values.attendence_time).format("HH:mm"),
        values.petId
      );
      setCpf("");
      closeModal();
      form.resetFields();
      await handleGetPets();
      setSearchPetList(false);
    },
    [form, closeModal, selectedId, handlePostPetCampaign, handleGetPets]
  );

  const handleSubmitStatus = useCallback(
    async (values: any) => {
      await handleDenyAttendence(selectedId, values.reason);
      setIsModalOpenReason(false);
      await handleGetPets();
      rejectForm.resetFields();
      setSearchPetList(false);
    },
    [selectedId, handleGetPets, rejectForm, handleDenyAttendence]
  );

  const handleConfirmPet = useCallback(
    async (petId: string) => {
      await handleConfirmAttendence(petId);
      await handleGetPets();
    },
    [handleGetPets, handleConfirmAttendence]
  );

  const columns: TableProps<ICampaignPet>["columns"] = useMemo(
    () => [
      {
        title: "Pet",
        dataIndex: "name",
        key: "name",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => <Text>{record.pet.name}</Text>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text>
            {CampaignPetStatus[record.status as keyof typeof CampaignPetStatus]}
          </Text>
        ),
      },
      {
        title: "Horário",
        dataIndex: "time",
        key: "time",
        hidden: listType === "queue",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => {
          const today = dayjs().startOf("day");
          const campaignDate = dayjs(record.attendence_time).startOf("day");

          const canShowTimePicker =
            userContext.user?.scopes?.includes("campaign:pet:update_time") &&
            !today.isBefore(campaignDate);

          const canShowText = userContext.user?.scopes?.includes(
            "campaign:pet:read:service_provider"
          );

          return (
            <>
              {canShowTimePicker && (
                <TimePicker
                  data-testid="time-picker"
                  format="HH:mm"
                  minuteStep={5}
                  showNow={false}
                  allowClear={false}
                  onChange={(date) =>
                    changeAttendenceTime(record.id, date.format("HH:mm"))
                  }
                  value={dayjs(record.attendence_time ?? "00:00", "HH:mm")}
                />
              )}
              {canShowText && (
                <Text>
                  {dayjs(record.attendence_time ?? "00:00", "HH:mm").format(
                    "HH:mm"
                  )}
                </Text>
              )}
            </>
          );
        },
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        render: (_: any, record) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes("campaign:pet:read") && (
                <Button
                  data-testid="view-pet"
                  className="p-2"
                  type="text"
                  onClick={() => setSelectedPet(record.pet)}
                >
                  <FaEye size={18} />
                </Button>
              )}

              {userContext.user?.scopes?.includes(
                "campaign:pet:update_status:attendence"
              ) && (
                <Button
                  data-testid="edit-status-pet"
                  className="p-2"
                  type="text"
                  onClick={() => handleConfirmPet(record.id)}
                >
                  <FaRegCircleCheck size={18} />
                </Button>
              )}

              {userContext.user?.scopes?.includes(
                "campaign:pet:update_status"
              ) &&
                location.pathname === ROUTES.DASHBOARD.CAMPAIGN.QUEUE && (
                  <Button
                    data-testid="edit-status-pet"
                    className="p-2"
                    type="text"
                    onClick={() => openModalRejectReason(record.id)}
                  >
                    <MdCancel size={18} />
                  </Button>
                )}

              {userContext.user?.scopes?.includes(
                "campaign:pet:update_status:queue"
              ) &&
                listType === "queue" && (
                  <Tooltip title="Colocar na fila">
                    <Button
                      data-testid="send-to-queue"
                      className="p-2"
                      type="text"
                      onClick={() => sendToQueue(record.id)}
                    >
                      <FaRegCircleCheck size={18} />
                    </Button>
                  </Tooltip>
                )}
            </Space>
          );
        },
      },
    ],
    [
      listType,
      userContext.user?.scopes,
      changeAttendenceTime,
      location.pathname,
      handleConfirmPet,
      openModalRejectReason,
      sendToQueue,
    ]
  );

  return (
    <>
      <Card
        className="w-full"
        title={
          <div className="flex justify-between items-center">
            <span>{`${campaignLocation.neighborhood} - ${dayjs(
              campaignLocation.date
            ).format("DD/MM/YYYY")}`}</span>
            <Flex gap="small" justify="flex-end" align="end">
              {userContext.user?.scopes?.includes(
                "campaign:pet:read:service_provider"
              ) && (
                <Button
                  onClick={() => openModal(campaignLocation?.id as string)}
                  status="info"
                >
                  Adicionar na fila
                </Button>
              )}
            </Flex>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Table
            columns={columns.filter((column) => !column.hidden)}
            dataSource={campaignPetList}
          />
        </Spin>
      </Card>

      <Modal
        open={!!selectedPet}
        onCancel={() => setSelectedPet(undefined)}
        footer={null}
      >
        <Flex vertical gap={"large"} className="mt-7">
          <PetCard {...selectedPet} />
          {selectedPet?.tutor && <TutorCard {...selectedPet?.tutor} />}
        </Flex>
      </Modal>
      <Modal
        title="Adicionar Pet na Fila"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button
            status="success"
            key="submit"
            data-testid="success-modal-button"
            onClick={form.submit}
            loading={false}
          >
            Enviar
          </Button>,
          <Button
            data-testid="unsuccess-modal-button"
            key="back"
            status="danger"
            onClick={closeModal}
          >
            Cancelar
          </Button>,
        ]}
      >
        <AntForm form={form} onFinish={handleSubmit} layout="vertical">
          <Card>
            <Text strong>CPF </Text>
            <Space.Compact style={{ width: "100%", marginBottom: "16px" }}>
              <MaskedInput
                placeholder="Insira o CPF"
                value={cpf}
                mask={"000.000.000-00"}
                onChange={(e) => setCpf(e.unmaskedValue)}
              />
              <Button status="info" onClick={handleSearch}>
                Buscar
              </Button>
            </Space.Compact>

            {searchPetList && (
              <Row gutter={[16, 16]}>
                {petList && petList.length > 0 ? (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <AntForm.Item
                        name="petId"
                        label="Lista de pets"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, selecione um pet",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          className="w-100"
                          options={petList.map((pet) => ({
                            value: pet.id,
                            label: pet.name,
                          }))}
                        />
                      </AntForm.Item>
                    </Col>
                    <Col span={24}>
                      <AntForm.Item
                        name="attendence_time"
                        label="Selecione um Horario"
                      >
                        <TimePicker
                          format="HH:mm"
                          minuteStep={5}
                          showNow={false}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                ) : (
                  <Empty />
                )}
              </Row>
            )}
          </Card>
        </AntForm>
      </Modal>
      <Modal
        title="Justifique a rejeição"
        open={isModalOpenReason}
        onCancel={closeModalRejectReason}
        footer={[
          <Button key="back" onClick={closeModalRejectReason}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            onClick={rejectForm.submit}
            data-testid="send-button-modal"
            loading={false}
          >
            Enviar
          </Button>,
        ]}
      >
        <AntForm
          layout="vertical"
          onFinish={handleSubmitStatus}
          form={rejectForm}
        >
          <AntForm.Item
            name="reason"
            label="Justificativa"
            rules={[
              {
                required: true,
                message: "Por favor, adicione uma justificativa.",
              },
            ]}
          >
            <Input.TextArea
              className="h-32 resize-none"
              placeholder="Adicione uma justificativa."
            />
          </AntForm.Item>
        </AntForm>
      </Modal>
    </>
  );
};
