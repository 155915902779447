import { api } from ".";
import { IItem } from "../../domain/entities/Item";

export async function getStock(): Promise<IItem[] | undefined> {
  return await api.get(`/api/v1/item/stock`);
}

export async function putStock(id: string, allocation: number) {
  await api.put(`/api/v1/item/allocate/${id}`, { allocated: allocation });
}
