import { useCallback, useContext, useState } from "react";
import {
  CampaignPetFilter,
  ICampaign,
  ICampaignLocations,
  ICampaignPet,
} from "../../domain/entities/Campaign";
import {
  getAllCampaign,
  getAllPetsByLocation,
  getAllPetsByLocationServiceProvider,
  getCampaign,
  patchAttendenceTime,
  patchRejectCampaign,
  patchUpdateStatus,
  postAttendenceTime,
  postCampaign,
  sendToQueue,
} from "../../data/service/campaign";
import { toast } from "react-toastify";
import { UserContext } from "../../domain/context/user/UserContext";
import dayjs, { Dayjs } from "dayjs";
import { uploadFile } from "../../data/service/bucket";

export function useCampaign() {
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState<ICampaign | undefined>();
  const [campaignList, setCampaignList] = useState<ICampaign[] | undefined>();
  const [campaignPetList, setCampaignPetList] = useState<
    ICampaignPet[] | undefined
  >();

  const userContext = useContext(UserContext);

  const handleGetCampaign = useCallback(async (id: string) => {
    setLoading(true);
    const selectedCampaign = await getCampaign(id);
    setCampaign(selectedCampaign);
    setLoading(false);
  }, []);

  const handleGetAllCampaign = useCallback(async () => {
    setLoading(true);
    const selectedAllCampaign = await getAllCampaign();
    setCampaignList(selectedAllCampaign);
    setLoading(false);
  }, []);

  const handleCreateCampaign = useCallback(async (data: any) => {
    setLoading(true);
    try {
      const body = data;

      const campaign_location = body.campaign_locations.map(
        (location: ICampaignLocations): ICampaignLocations => ({
          ...location,
          date: dayjs(location.date).format("YYYY-MM-DD"),
        })
      );

      body.campaign_locations = campaign_location;

      const [campaign_start_date, campaign_end_date] = body.campaign_date.map(
        (date: Dayjs) => dayjs(date).format("YYYY-MM-DD")
      );

      const [subscription_start_date, subscription_end_date] =
        body.subscription_date.map((date: Dayjs) =>
          dayjs(date).format("YYYY-MM-DD")
        );

      delete body.campaign_date;
      delete body.subscription_date;
      body.campaign_start_date = campaign_start_date;
      body.campaign_end_date = campaign_end_date;
      body.subscription_start_date = subscription_start_date;
      body.subscription_end_date = subscription_end_date;

      body.image_url = await uploadFile(body.image_url.file);
      body.notice_url = await uploadFile(body.notice_url.file);

      await postCampaign(body);
      toast.success("Campanha criada com sucesso");
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetAllCampaignPetsByLocation = useCallback(
    async (id: string, listType: string, filter: CampaignPetFilter) => {
      setLoading(true);
      try {
        let petList: ICampaignPet[] | undefined = [];
        if (userContext.user?.scopes?.includes("campaign:pet:read:all")) {
          petList = await getAllPetsByLocation(id, listType, filter);
        }

        if (
          userContext.user?.scopes?.includes(
            "campaign:pet:read:service_provider"
          )
        ) {
          petList = await getAllPetsByLocationServiceProvider(id);
        }

        setCampaignPetList(petList);
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    [userContext.user?.scopes]
  );

  const handleSendToQueue = useCallback(async (id: string) => {
    setLoading(true);
    try {
      sendToQueue(id);
      toast.success("Pet enviado para a fila");
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeAttendenceTime = useCallback(
    async (id: string, attendenceTime: string) => {
      setLoading(true);
      try {
        patchAttendenceTime(id, attendenceTime);
        toast.success("Horário do Pet atualizado com sucesso");
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handlePostPetCampaign = useCallback(
    async (
      campaignLocationId: string,
      attendenceTime: string,
      petId: string
    ) => {
      setLoading(true);
      try {
        await postAttendenceTime(campaignLocationId, attendenceTime, petId);
        toast.success("Pet adicionado com sucesso");
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handlePatchRejectReason = async (id: string, rejectData: string) => {
    setLoading(true);
    await patchRejectCampaign(id, rejectData);
    toast.success("Camapanha cancelada com sucesso");
    setLoading(false);
  };

  const handleConfirmAttendence = useCallback(async (petId: string) => {
    setLoading(true);
    try {
      await patchUpdateStatus(petId, "ATTENDED");
      toast.success("Pet Atendido");
    } catch (error: any) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDenyAttendence = useCallback(
    async (petId: string, reason: string) => {
      setLoading(true);
      try {
        await patchUpdateStatus(petId, "NOT_ATTENDED", reason);
        toast.success("Pet Atendido");
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    campaign,
    campaignList,
    campaignPetList,
    actions: {
      handleCreateCampaign,
      handleGetCampaign,
      handleGetAllCampaign,
      handleGetAllCampaignPetsByLocation,
      handleSendToQueue,
      handleChangeAttendenceTime,
      handlePostPetCampaign,
      handleConfirmAttendence,
      handleDenyAttendence,
      handlePatchRejectReason,
    },
  };
}
