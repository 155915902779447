import React, { useCallback, useState } from "react";
import {
  Form as AntForm,
  Input,
  Row,
  Col,
  Flex,
  Card,
  Spin,
  Select,
} from "antd";
import { Button } from "../../../../components/Button";
import { useConfig } from "../../../../hooks/useConfig";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Router";

export const Form: React.FC = () => {
  const [form] = AntForm.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [tagError, setTagError] = useState("");

  const {
    service_tags,
    loading,
    actions: { handleSubmitService },
  } = useConfig();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await handleSubmitService(values);
        navigate(ROUTES.DASHBOARD.SERVICE.ROOT);
      } catch (error: any) {
        new Error(error);
      }
    },
    [handleSubmitService, navigate]
  );

  const handleTagChange = (value: string[]) => {
    const lowerCaseTags = value.map((tag) => tag.toLowerCase());
    const validTags = service_tags.map((tag) => tag.toLowerCase());
    if (lowerCaseTags.length === 0) {
      setSubmitDisabled(true);
      setTagError("Ao menos uma tag é obrigatória.");
    } else if (lowerCaseTags.includes("outros") && lowerCaseTags.length > 1) {
      setSubmitDisabled(true);
      setTagError(
        'A tag "Outros" não pode ser selecionada junto a outras tags.'
      );
    } else if (!lowerCaseTags.every((tag) => validTags.includes(tag))) {
      setSubmitDisabled(true);
      setTagError("Insira apenas tags válidas.");
    } else {
      setSubmitDisabled(false);
      setTagError("");
      form.setFields([{ name: "tags", errors: [] }]);
    }
    form.setFieldsValue({ tags: value });
  };

  return (
    <AntForm
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      className="w-full"
    >
      <Spin spinning={loading}>
        <Flex vertical gap={"middle"}>
          <Card>
            <AntForm.Item name={"id"} noStyle hidden>
              <Input />
            </AntForm.Item>
            <Flex vertical gap="middle">
              <Card>
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} md={12}>
                    <AntForm.Item
                      name={"service_name"}
                      label="Nome"
                      rules={[
                        { required: true, message: "Nome é obrigatório" },
                      ]}
                    >
                      <Input placeholder="Nome" />
                    </AntForm.Item>
                  </Col>
                  <Col span={24}>
                    <AntForm.Item
                      name={"tags"}
                      label="Tags"
                      rules={[
                        {
                          required: true,
                          message: "Ao menos uma tag é obrigatória",
                        },
                      ]}
                      validateStatus={tagError ? "error" : ""}
                      help={tagError}
                    >
                      <Select
                        mode="tags"
                        className="w-full"
                        data-testid="service-tags"
                        placeholder="Tags dos Serviços"
                        options={service_tags.map((tag) => ({
                          label: tag,
                          value: tag,
                        }))}
                        onChange={handleTagChange}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
              </Card>
              <Flex justify="flex-end" gap={"small"}>
                <Button
                  htmlType="button"
                  status="info"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
                <AntForm.Item noStyle>
                  <Button
                    htmlType="submit"
                    status="success"
                    disabled={submitDisabled}
                  >
                    Enviar
                  </Button>
                </AntForm.Item>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Spin>
    </AntForm>
  );
};
